import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum FOOTER_COLOR_TYPES {
  white = '#fff',
  grayLight = '#EFEFEF'
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {

  @Input() backgroundColor = FOOTER_COLOR_TYPES.grayLight;

  constructor() { }

}
