import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobLocation'
})
export class JobLocationPipe implements PipeTransform {

  transform(value: string, cut: boolean): unknown {
    if (!cut) {
      return value;
    }

    const index = value.indexOf('(');

    return index >= 0 ? value.slice(0, index).trim(): value;
  }

}
