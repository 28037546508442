export interface CountdownSettings {
  enable: boolean;
  time: number; // countdown time in seconds
  currentTime: number;
  displayOverlay: boolean;
}

export const DEFAULT_COUNTDOWN_TIME = 3;
export const MAX_RECORDING_DURATION = 600;
export const MAX_VIDEO_UPLOAD_DURATION = 183;
export const MIN_RECORDING_DURATION = 3;
export const VIDEO_QUESTIONS_COUNT = 3;

export const STAGING_STREAMING_KEY = 'T-49d2016b-e492-4851-b9b4-d103244a2fdd';

export enum PlayerState {
  paused = 'PAUSED',
  playing = 'PLAYING',
  finished = 'FINISHED'
}

export enum VideoMode {
  review = 'REVIEW',
  record = 'RECORD',
  void = 'VOID'
}
