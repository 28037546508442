import { Injectable } from '@angular/core';
import { Quiz, Question } from '../models/quiz.model';

@Injectable({ providedIn: 'root' })
export class QuizStoreService {

  private _quiz: Quiz;

  constructor() { }

  get quiz(): Quiz {
    return this._quiz;
  }

  set quiz(quiz: Quiz) {
    this._quiz = quiz;
  }

  get activeQuestionIndex(): number {
    return this._quiz.activeQuestionIndex;
  }

  set activeQuestionIndex(activeQuestionIndex: number) {
    this._quiz.activeQuestionIndex = activeQuestionIndex;
  }

  updateQuestionAnswer(questionIndex: number, answerIndex: number): void {
    this._quiz.questions[questionIndex].selectedAnswer = answerIndex;
  }

  reset(): void {
    this._quiz = null;
  }

  saveKnockoutQuestions(): void {
    const answers: number[] = [];

    this._quiz.questions
      .forEach((question: Question) => {
        answers.push(question.selectedAnswer);
      });

    sessionStorage.setItem('answers', JSON.stringify(answers));
  }
}
