import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TrimValueAccessorDirective } from './trim.directive';
import { CustomCarouselComponent } from './custom-carousel/custom-carousel.component';
import { CopyToClipboardDirective } from './copy-to-clipboard.directive';
import { FooterComponent } from './footer/footer.component';
import { VideoReplayComponent } from './video-replay/video-replay.component';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { JobLocationPipe } from './pipes/job-location.pipe';

@NgModule({
  declarations: [
    TrimValueAccessorDirective,
    CopyToClipboardDirective,
    CustomCarouselComponent,
    FooterComponent,
    VideoReplayComponent,
    OutsideClickDirective,
    JobLocationPipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    TranslateModule,
    TrimValueAccessorDirective,
    CopyToClipboardDirective,
    CustomCarouselComponent,
    FooterComponent,
    VideoReplayComponent,
    OutsideClickDirective,
    JobLocationPipe
  ]
})
export class SharedModule { }
