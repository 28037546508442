<div class="player-container"
     [ngStyle]="{
       'background-image': player.readyState < 2 && showBackgroundImage ? 'url(' + videoPosterUrl + ')' : 'none'
     }">
  <div class="player-overlay"
       *ngIf="useCustomControls"
       [ngClass]="{
         playing: playerState === playerStateEnum.playing
       }"
       (click)="togglePlayPause($event)">
    <div class="player-state-icon-wrapper">
      <i class="icon-play" *ngIf="playerState === playerStateEnum.paused"></i>
      <i class="icon-redo" *ngIf="playerState === playerStateEnum.finished"></i>
      <i class="icon-pause"></i>
    </div>
  </div>
  <video #player
         [poster]="videoPosterUrl"
         (canplay)="videoReady()"
         (ended)="videoEnded()"
         (timeupdate)="videoTimeUpdated()"
         (loadedmetadata)="setVideoStyles()"
         preload="metadata"
         [muted]="autoplay"
         [autoplay]="autoplay"
         [loop]="useLoop"
         [ngClass]="{
           'centered-video': useCenteredVideo
         }"
         [controls]="useControls"
         playsinline>
    <source [src]="smallVideoMp4Url" *ngIf="smallVideoMp4Url" />
    <source [src]="videoMp4Url" />
    <source [src]="videoWebmUrl" *ngIf="videoWebmUrl" />
  </video>
</div>
<div class="progress-tracker-container" *ngIf="useProgressTracker">
  <div class="progress-tracker">
    <div class="progress"
         [ngStyle]="{width: playbackProgress + '%'}"></div>
    <div class="track"></div>
    <div class="indicator"
         [ngStyle]="{left: 'calc(' + playbackProgress + '% - 1.1rem)'}"></div>
  </div>
</div>
