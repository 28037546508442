import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  renderer: Renderer2;

  private _loaderStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  get loader$(): Observable<any> {
    return this._loaderStatus$.asObservable();
  }

  show(): void {
    this.renderer.addClass(document.querySelector('app-root'), 'loader-active');
    this._loaderStatus$.next(true);
  }

  hide(): void {
    this.renderer.removeClass(document.querySelector('app-root'), 'loader-active');
    this._loaderStatus$.next(false);
  }

}
